import { IEditorBlock } from 'components/bricks/types/editorBlock.type';
import { MODEL_SETTINGS } from 'components/bricks/constants';
import { SNAPCHAT_OPTIMIZATION_GOALS } from '../data/channels';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        opened: true,
        items: [
            {
                type: 'text',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Budget & Schedule',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'delivery_constraint',
                identifier: 'snapchat_ad_squad-delivery_constraint',
                label: 'Budget type',
                tooltip: 'Type of the budget',
                options: {
                    DAILY_BUDGET: 'Daily budget',
                    LIFETIME_BUDGET: 'Lifetime budget'
                }
            },
            {
                type: 'number',
                tooltip: 'Daily budget micro',
                identifier: 'snapchat_ad_squad-budget',
                model: 'budget',
                label: 'Budget'
            },
            // {
            //     type: 'number',
            //     tooltip: 'Lifetime budget micro',
            //     model: 'lifetime_budget_micro',
            //     label: 'Lifetime budget'
            // },
            {
                type: 'date',
                identifier: 'snapchat_ad_squad-start_time',
                model: 'start_time',
                label: 'Start date'
            }
        ]
    },
    {
        title: 'Locations',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.geos',
                identifier: 'snapchat_ad_squad-targeting-geos',
                label: 'Locations',
                metadataType: 'snapchatGeoLocation',
                config: {
                    countryCode: 'bg',
                    type: 'country'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Demographics',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.demographics.age_groups',
                identifier: 'snapchat_ad_squad-targeting-age_groups',
                label: 'Age group',
                metadataType: 'snapchatDemographic',
                config: {
                    type: 'age_group'
                },
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'targeting.demographics.gender',
                identifier: 'snapchat_ad_squad-targeting-gender',
                label: 'Genders',
                options: {
                    MALE: 'Male',
                    FEMALE: 'Female',
                    GENDER_UNLIMITED: 'Unlimited'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.demographics.languages',
                identifier: 'snapchat_ad_squad-targeting-languages',
                label: 'Languages',
                metadataType: 'snapchatDemographic',
                config: {
                    type: 'languages'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Audiences',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'metadataInput',
                model: 'targeting.interests',
                identifier: 'snapchat_ad_squad-targeting-interests',
                label: 'Predefined Audiences',
                metadataType: 'snapchatInterest',
                config: {
                    type: 'scls'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Devices',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'buttonGroup',
                model: 'targeting.devices.os_type',
                identifier: 'snapchat_ad_squad-targeting-os_type',
                label: 'Operating systems',
                options: {
                    ALL: 'All',
                    ANDROID: 'Android',
                    IOS: 'iOS'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.devices.marketing_name',
                identifier: 'snapchat_ad_squad-targeting-marketing_name',
                label: 'Device Makes',
                metadataType: 'snapchatDevice',
                config: {
                    type: 'marketing_name'
                },
                useValueObject: false
            },
            {
                type: 'buttonGroup',
                model: 'targeting.devices.connection_type',
                identifier: 'snapchat_ad_squad-targeting-connection_type',
                label: 'Connection type',
                options: {
                    ALL: 'All',
                    CELL: 'Cell',
                    WIFI: 'WiFi'
                }
            },
            {
                type: 'metadataInput',
                model: 'targeting.devices.carrier',
                identifier: 'snapchat_ad_squad-targeting-carrier',
                label: 'Carriers',
                metadataType: 'snapchatDevice',
                config: {
                    type: 'carrier'
                },
                useValueObject: false
            }
        ]
    },
    {
        title: 'Delivery',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'status',
                label: 'Status',
                identifier: 'snapchat_ad_squad-status',
                tooltip: 'Status',
                options: {
                    PAUSED: 'PAUSED',
                    ACTIVE: 'ACTIVE'
                }
            },
            // Dynamically generate optimization goal inputs from the mapped objectives
            ...Object.entries(SNAPCHAT_OPTIMIZATION_GOALS).map(([objective, options]) => ({
                type: 'select',
                model: 'optimization_goal',
                identifier: 'snapchat_ad_squad-optimization_goal',
                label: 'Optimization goal',
                condition: `additionalVars.snapchat_campaign.${MODEL_SETTINGS}.objective === '${objective}'`,
                tooltip:
                    "Define advertising goals you want to achieve when social platform delivers your ads. Ad set's optimization_goal is used to decide which people get your ad. For example, with App installs, Snapchat delivers your ad to people who are more likely to install your app.",
                options
            })),
            {
                type: 'select',
                model: 'bid_strategy',
                identifier: 'snapchat_ad_squad-bid_strategy',
                label: 'Bid strategy',
                defaultValue: 'AUTO_BID',
                tooltip:
                    'Choose bid strategy for this campaign to suit your specific business goals. Each strategy has tradeoffs and may be available for certain optimization_goals',
                options: {
                    AUTO_BID: 'Auto bid',
                    LOWEST_COST_WITH_MAX_BID: 'Lowest cost with max bid',
                    TARGET_COST: 'Target cost'
                }
            },
            {
                type: 'number',
                tooltip: 'Bid price micro',
                model: 'bid_micro',
                identifier: 'snapchat_ad_squad-bid_micro',
                label: 'Bid price'
            },
            {
                type: 'select',
                model: 'pacing_type',
                label: 'Pacing type',
                identifier: 'snapchat_ad_squad-pacing_type',
                tooltip: 'Pacing type',
                options: {
                    STANDARD: 'Standard',
                    ACCELERATED: 'Accelerated'
                }
            },
            {
                type: 'select',
                model: 'billing_event',
                label: 'Billing event',
                identifier: 'snapchat_ad_squad-billing_event',
                tooltip: 'The billing event for this ad set',
                defaultValue: 'IMPRESSIONS',
                options: {
                    IMPRESSIONS: 'Impressions'
                }
            },
            {
                type: 'autocomplete',
                tooltip: 'Data derived from third party tags, including measurement reports',
                model: 'measurement_provider_names',
                identifier: 'snapchat_ad_squad-measurement_provider_names',
                label: 'Third party tagging',
                options: ['MOAT_SS', 'DOUBLEVERIFY']
            }
        ]
    },
    //TODO: TO be discussed
    // {
    //     title: 'Ad Set Details',
    //     itemType: 'block',
    //     type: 'multiInput',
    //     opened: true,
    //     items: [
    //         {
    //             type: 'buttonGroup',
    //             model: 'forced_view_setting',
    //             label: 'Forced view setting',
    //             options: {
    //                 FULL_DURATION: 'Full duration',
    //                 SIX_SECONDS: 'Six seconds'
    //             }
    //         },

    //         {
    //             type: 'text',
    //             model: 'type',
    //             label: 'Ad squad type',
    //             disabled: true,
    //             useValueObject: false
    //         },

    //         {
    //             type: 'number',
    //             tooltip: 'Roas value micro',
    //             model: 'roas_value_micro',
    //             label: 'Bid price'
    //         },
    //         {
    //             type: 'text',
    //             model: 'reach_and_frequency_status**',
    //             label: 'Reach and frequency status',
    //             disabled: true,
    //             useValueObject: false
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Reach goal as specified in the Forecasting request',
    //             model: 'reach_goal**',
    //             label: 'Reach goal'
    //         },
    //         {
    //             type: 'number',
    //             tooltip: 'Reach goal as specified in the Forecasting request',
    //             model: 'impression_goal**',
    //             label: 'Impression goal'
    //         }
    //     ]
    // },
    {
        title: 'Planning',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'dateRange',
                model: 'planning',
                label: 'Schedule date & time'
            }
        ]
    }
];

export default settings;
