import { Channels } from 'components/bricks/types/channels';

export const channels: Channels = {
    snapchat: 'Snapchat'
};

export const SNAPCHAT_OPTIMIZATION_GOALS = {
    BRAND_AWARENESS: {
        IMPRESSIONS: 'Cost Per 1000 Impressions (CPM)',
        SWIPES: 'Target Cost Per Swipe',
        STORY_OPENS: 'Target Cost Per Story Open for Story Ads'
    },
    APP_INSTALLS: {
        IMPRESSIONS: 'Cost Per 1000 Impressions (CPM)',
        SWIPES: 'Target Cost Per Swipe',
        APP_INSTALLS: 'Target Cost Per Install',
        APP_ADD_TO_CART: 'Target Cost Per ADD_TO_CART on app',
        APP_PURCHASE: 'Target Cost Per PURCHASE on app',
        APP_SIGNUP: 'Target Cost Per SIGNUP on app'
    },
    APP_CONVERSION: {
        SWIPES: 'Target Cost Per Swipe',
        APP_INSTALLS: 'Target Cost Per Install',
        APP_ADD_TO_CART: 'Target Cost Per ADD_TO_CART on app',
        APP_PURCHASE: 'Target Cost Per PURCHASE on app',
        APP_SIGNUP: 'Target Cost Per SIGNUP on app'
    },
    VIDEO_VIEW: {
        IMPRESSIONS: 'Cost Per 1000 Impressions (CPM)',
        VIDEO_VIEWS: 'Target Cost per 2 second Video View',
        VIDEO_VIEWS_15_SEC: 'Target Cost per 15 second Video View'
    },
    WEB_CONVERSION: {
        SWIPES: 'Target Cost Per Swipe',
        PIXEL_PAGE_VIEW: 'Target Cost Per PAGE_VIEW on web',
        PIXEL_ADD_TO_CART: 'Target Cost Per ADD_TO_CART on web',
        PIXEL_PURCHASE: 'Target Cost Per PURCHASE on web',
        PIXEL_SIGNUP: 'Target Cost Per SIGNUP on web'
    },
    ENGAGEMENT: {
        SWIPES: 'Target Cost Per Swipe',
        STORY_OPENS: 'Target Cost Per Story Open for Story Ads'
    },
    LEAD_GENERATION: {
        SWIPES: 'Target Cost Per Swipe',
        STORY_OPENS: 'Target Cost Per Story Open for Story Ads',
        LEAD_FORM_SUBMISSIONS: 'Target Cost Per Lead Form Submission'
    },
    PROMOTE_PLACES: {
        SWIPES: 'Target Cost Per Swipe'
    },
    REENGAGEMENT: {
        APP_REENGAGE_OPEN: 'Target Cost Per App Open',
        APP_REENGAGE_PURCHASE: 'Target Cost Per App Purchase'
    }
};
