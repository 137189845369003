import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                identifier: 'x_campaign-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'X campaign',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Campaign fields',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'entity_status',
                identifier: 'x_campaign-entity_status',
                label: 'Status',
                tooltip: 'The current status of the campaign.',
                options: {
                    ACTIVE: 'Active',
                    PAUSED: 'Paused'
                }
            },
            {
                type: 'select',
                model: 'objective',
                identifier: 'x_campaign-objective',
                label: 'Objective',
                tooltip: `The marketing objective that defines the campaign’s primary goal.`,
                options: {
                    REACH: 'Reach',
                    TWEET_ENGAGEMENTS: 'Tweet engagements',
                    VIDEO_VIEWS: 'Video views',
                    WEBSITE_CLICKS: 'Website clicks',
                    CONVERSIONS: 'Conversions',
                    LEAD_GENERATION: 'Lead generation'
                }
            },
            {
                type: 'datetime',
                model: 'start_time',
                tooltip: 'The scheduled start date/time for the campaign.',
                identifier: 'x_campaign-start_time',
                label: 'Start time'
            },
            {
                type: 'datetime',
                model: 'end_time',
                tooltip: 'The scheduled end date/time for the campaign. (May be left empty for campaigns with no fixed end date)',
                identifier: 'x_campaign-end_time',
                label: 'End time'
            },
            {
                type: 'select',
                model: 'funding_instrument_id',
                identifier: 'x_campaign-funding_instrument_id',
                label: 'Funding instrument',
                tooltip: 'The funding instrument (payment source) used to pay for this campaign.',
                options: {
                    FI_123: 'Credit card',
                    FI_456: 'Debit card',
                    FI_789: 'Account balance'
                }
            },
            {
                type: 'number',
                tooltip: 'The maximum amount to spend per day on the campaign (in micro units of your local currency).',
                identifier: 'x_campaign-daily_budget_amount_local_micro',
                model: 'daily_budget_amount_local_micro',
                label: 'Daily Budget (in micro currency)'
            },
            {
                type: 'number',
                tooltip: 'The overall budget allocated for the campaign (in micro units).',
                identifier: 'x_campaign-total_budget_amount_local_micro',
                model: 'total_budget_amount_local_micro',
                label: 'Total Budget (in micro currency)'
            }
        ]
    }
];

export default settings;
