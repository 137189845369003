import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                identifier: 'x_adset-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'X ad set',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Ad set fields',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'datetime',
                model: 'start_time',
                tooltip: 'The scheduled start date/time for the ad set.',
                identifier: 'x_adset-start_time',
                label: 'Start time'
            },
            {
                type: 'datetime',
                model: 'end_time',
                tooltip: 'The scheduled end date/time for the ad set. (May be optional or left open.)',
                identifier: 'x_adset-end_time',
                label: 'End time'
            },
            {
                type: 'select',
                model: 'entity_status',
                identifier: 'x_adset-entity_status',
                label: 'Status',
                tooltip: 'The current status of the ad set.',
                options: {
                    ACTIVE: 'Active',
                    PAUSED: 'Paused',
                    DELETED: 'Deleted',
                    ARCHIVED: 'Archived'
                }
            },
            {
                type: 'select',
                model: 'optimization',
                identifier: 'x_adset-optimization',
                label: 'Optimization',
                tooltip: 'The optimization goal that will drive bid decisions for this ad set.',
                options: {
                    NONE: 'None',
                    ENGAGEMENT: 'Engagement',
                    VIDEO_VIEWS: 'Video views',
                    CONVERSIONS: 'Conversions',
                    CLICK: 'Clicks'
                }
            },
            {
                type: 'checkboxList',
                model: 'placements',
                itemType: 'input',
                identifier: 'x_adset-placements',
                label: 'Placements',
                tooltip: 'The network placements where the ads will be shown.',
                options: {
                    ALL_ON_TWITTER: 'All on Twitter',
                    PUBLISHER_NETWORK: 'Publisher network',
                    PARTNER_NETWORK: 'Partner network'
                }
            },
            {
                type: 'number',
                tooltip: 'The bid amount for the ad set in micro units of your local currency.',
                identifier: 'x_adset-bid_amount_local_micro',
                model: 'bid_amount_local_micro',
                label: 'Bid Amount (in micro currency)'
            }
        ]
    }
];

export default settings;
