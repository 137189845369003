import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import { ValidationObject } from 'components/bricks/types/validator.type';

// Ad setup data from the brick object
const brickAdSetup = 'brick.data?.adSetup?';

// Ad setup is of type 'single'
const isSingleAdSetup = `${brickAdSetup}.type === 'single'`;

// Targeting placements from the parent settings
const parentSettingsPlacementTargeting = `parent?.data?.settings?.targeting?.placements?`;

const validators: ValidationObject = {
    validators: [
        {
            condition: `!brick.data?.adSetup`,
            message: 'There is no data in ad setup',
            inputIdentifiers: ['tiktok_ad-ad_setup'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && !${brickAdSetup}.items[0].creativeIds?.length`,
            message: 'There are no creatives',
            inputIdentifiers: ['tiktok_ad-ad_setup-creative_input'],
            severity: 'error',
            tabKey: 'adSetup'
        },
        {
            condition: `(!${parentSettingsPlacementTargeting}.length || ${parentSettingsPlacementTargeting}.includes("tiktok-feed")) && ${isSingleAdSetup} && ${brickAdSetup}.items?.[0] && (!${brickAdSetup}.items[0].creativeIds || !${brickAdSetup}.items[0].creativeIds?.length || !${brickAdSetup}.items[0].placements["tiktok-feed"])`,
            message: 'Tiktok Feed has not assigned creative',
            inputIdentifiers: ['tiktok_ad-ad_setup-placements-tiktok-feed-0'],
            tabKey: 'adSetup',
            severity: 'warning'
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'displayName'),
            message: 'Display name is required',
            inputIdentifiers: ['tiktok_ad-ad_setup-displayName'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Display name is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.displayName.length > 40`,
            message: 'Display name must be 40 characters or less',
            inputIdentifiers: ['tiktok_ad-ad_setup-displayName'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 40,
                    message: 'Display name must be 40 characters or less'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'adText'),
            message: 'Ad text is required',
            inputIdentifiers: ['tiktok_ad-ad_setup-adText'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Ad text is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.adText?.match(/\\b\\w{31,}\\b/)`,
            message: 'Ad text should not contain words longer than 30 characters',
            inputIdentifiers: ['tiktok_ad-ad_setup-adText'],
            severity: 'warning',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /\\b\\w{31,}\\b/ }],
                    severity: 'warning',
                    message: 'Ad text should not contain words longer than 30 characters'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.adText?.match(/[{}#]/g)`,
            message: "Ad text can't contain the {} and # characters",
            inputIdentifiers: ['tiktok_ad-ad_setup-adText'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ not_regex: /[{}#]/g }],
                    severity: 'error',
                    message: "Text can't contain the {} and # characters"
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.adText.length > 100`,
            message: 'Ad text length should be below 100 characters',
            inputIdentifiers: ['tiktok_ad-ad_setup-adText'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'length',
                    severity: 'error',
                    max: 100,
                    message: 'Ad text length should be below 100'
                }
            ]
        },
        {
            condition: ValidateHelpers.generateRequiredFieldItemValidator('single', 0, 'landingPageUrl'),
            message: 'Link is required',
            inputIdentifiers: ['tiktok_ad-ad_setup-landingPageUrl'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: 'required',
                    severity: 'error',
                    message: 'Link is required'
                }
            ]
        },
        {
            condition: `${isSingleAdSetup} && ${brickAdSetup}.items?.[0]?.landingPageUrl && !${brickAdSetup}.items[0].landingPageUrl?.${ValidateHelpers.getRegexMatch('extendedUrl')}`,
            message: 'Link is not a valid URL',
            inputIdentifiers: ['tiktok_ad-ad_setup-landingPageUrl'],
            severity: 'error',
            tabKey: 'adSetup',
            inputValidator: [
                {
                    type: [{ regex: /\b(https?:\/\/)?(?:www\.)\S+\.\S+\b/ }],
                    severity: 'error',
                    message: 'Link is not a valid URL'
                }
            ]
        }
    ]
};

export default validators;
