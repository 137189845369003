import { PlacementsSection } from 'components/bricks/types/placement.type';
import Translation from 'components/data/Translation';
import snapchatCollection from '../../../../../components/shared/components/placements-block/images/snapchat-collections.png';
import snapchatSingleAd from '../../../../../components/shared/components/placements-block/images/snapchat-single-ad.png';
import snapchatStory from '../../../../../components/shared/components/placements-block/images/snapchat-story.png';

const placements: PlacementsSection[] = [
    {
        key: 'story',
        label: Translation.get('adSetup.placements.story', 'bricks'),
        children: [
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'singleAd',
                key: 'snapchat-ad',
                label: 'Snapchat Single Ad',
                platform: 'snapchat',
                channel: 'snapchat',
                frameWidth: 340,
                multiFrame: false,
                icon: 'home',
                description: Translation.get('adSetup.placements.snapchatSingleAdDescription', 'bricks'),
                previewImage: snapchatSingleAd
            },
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'story',
                key: 'snapchat-story',
                label: 'Snapchat Story',
                platform: 'snapchat',
                channel: 'snapchat',
                frameWidth: 340,
                multiFrame: true,
                icon: 'auto_stories',
                description: Translation.get('adSetup.placements.snapchatStoryDescription', 'bricks'),
                previewImage: snapchatStory
            },
            {
                recommendedHeight: 16,
                recommendedWidth: 9,
                minWidth: 500,
                type: 'collection',
                key: 'snapchat-collection',
                label: 'Snapchat Collections',
                platform: 'snapchat',
                channel: 'snapchat',
                frameWidth: 340,
                multiFrame: false,
                icon: 'perm_media',
                description: Translation.get('adSetup.placements.snapchatCollectionDescription', 'bricks'),
                previewImage: snapchatCollection
            }
        ]
    }
];

export default placements;
