import { IEditorBlock } from 'components/bricks/types/editorBlock.type';

const settings: IEditorBlock[] = [
    {
        title: 'General',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'text',
                identifier: 'x_ad-title',
                model: 'title',
                itemType: 'input',
                label: 'Title',
                defaultValue: 'X ad',
                maxLength: 150,
                fixedModel: true,
                useValueObject: false
            }
        ]
    },
    {
        title: 'Ad (Promoted Tweet) Fields',
        itemType: 'block',
        type: 'multiInput',
        blockModel: 'settings',
        opened: true,
        items: [
            {
                type: 'select',
                model: 'entity_status',
                identifier: 'x_ad-entity_status',
                label: 'Status',
                tooltip: 'The current status of the ad.',
                options: {
                    ACTIVE: 'Active',
                    PAUSED: 'Paused',
                    DELETED: 'Deleted',
                    ARCHIVED: 'Archived'
                }
            },
            {
                type: 'text',
                model: 'card_id',
                label: 'Card ID',
                tooltip: 'If applicable, the ID of the “card” (interactive content module) associated with the ad creative',
                identifier: 'x_ad-card_id',
                useValueObject: false
            }
        ]
    }
];

export default settings;
